// export default {
//     s3: {
//       REGION: "eu-west-2",
//       BUCKET: "mark-iw-slsstack-demo"
//     },
//     apiGateway: {
//       REGION: "eu-west-2",
//       URL: "https://88hsduh9dd.execute-api.eu-west-2.amazonaws.com/prod"
//     },
//     cognito: {
//       REGION: "eu-west-2",
//       USER_POOL_ID: "eu-west-2_9KDmpWKaU",
//       APP_CLIENT_ID: "55h07fn79ke8i9pvk4o1uuktl9",
//       IDENTITY_POOL_ID: "eu-west-2:0fa9b53f-5e84-4071-913d-5011b4624978"
//     },
//     MAX_ATTACHMENT_SIZE: 5000000
//   };

  const dev = {
    s3: {
      REGION: "eu-west-2",
      BUCKET: "notes-app-2-api-dev-attachmentsbucket-13c7zx8e9a4hj"
    },
    apiGateway: {
      REGION: "eu-west-2",
      URL: "https://api.markdore.co.uk/dev"
    },
    cognito: {
      REGION: "eu-west-2",
      USER_POOL_ID: "eu-west-2_qoDZbJzAo",
      APP_CLIENT_ID: "4sqk11p5trf02b6kcj9oe9p3tm",
      IDENTITY_POOL_ID: "eu-west-2:e930c60f-56bb-4a9f-a7b1-a051d6146450"
    },
    STRIPE_KEY: "pk_test_g3X2a91RNbhPl16l6NEUdSH600DoPwCOUK"
  };
  
  const prod = {
    s3: {
      REGION: "eu-west-2",
      BUCKET: "notes-app-2-api-prod-attachmentsbucket-gxla2omg63r6"
    },
    apiGateway: {
      REGION: "eu-west-2",
      URL: "https://api.markdore.co.uk/prod"
    },
    cognito: {
      REGION: "eu-west-2",
      USER_POOL_ID: "eu-west-2_HDR1e5xD1",
      APP_CLIENT_ID: "hkom2tfrp849ge1b3ahv3d62q",
      IDENTITY_POOL_ID: "eu-west-2:a5d20c3c-eb60-4e12-9589-eeb21b5291bd"
    },
    STRIPE_KEY: "pk_test_g3X2a91RNbhPl16l6NEUdSH600DoPwCOUK"
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
  